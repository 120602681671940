exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirm-email-js": () => import("./../../../src/pages/confirmEmail.js" /* webpackChunkName: "component---src-pages-confirm-email-js" */),
  "component---src-pages-consent-js": () => import("./../../../src/pages/consent.js" /* webpackChunkName: "component---src-pages-consent-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stream-gnaj-js": () => import("./../../../src/pages/stream/gnaj.js" /* webpackChunkName: "component---src-pages-stream-gnaj-js" */),
  "component---src-pages-stream-krec-js": () => import("./../../../src/pages/stream/krec.js" /* webpackChunkName: "component---src-pages-stream-krec-js" */),
  "component---src-pages-stream-pastwisko-js": () => import("./../../../src/pages/stream/pastwisko.js" /* webpackChunkName: "component---src-pages-stream-pastwisko-js" */),
  "component---src-pages-stream-przedstawienie-js": () => import("./../../../src/pages/stream/przedstawienie.js" /* webpackChunkName: "component---src-pages-stream-przedstawienie-js" */),
  "component---src-pages-stream-staccienawiecej-js": () => import("./../../../src/pages/stream/staccienawiecej.js" /* webpackChunkName: "component---src-pages-stream-staccienawiecej-js" */),
  "component---src-pages-unsubscribe-email-js": () => import("./../../../src/pages/unsubscribeEmail.js" /* webpackChunkName: "component---src-pages-unsubscribe-email-js" */)
}

